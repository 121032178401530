import axios, { AxiosRequestConfig } from "axios";
import type { Request, Response } from "express";
import stringify from "fast-json-stable-stringify";
import { v4 as uuidv4 } from "uuid";

import { cookies } from "../utilities/cookie";
import { ILog } from "@ihr-radioedit/inferno-core";
import { md5 } from "@ihr-radioedit/inferno-core";
import type { Store } from "../stores";
import { IHeartUserProfile } from "../decoders/ihr-user";

const log = ILog.logger("ABTest/variant-test.ts");

interface ABTestRequestPayload {
  userId?: string;
  meta: {
    deviceId: string;
    platform: string;
    local_site_name: string;
  };
  eligibilityControlFilter?: "All" | "On" | "Off"; // Default: All
}

export interface ABTestConfig {
  at?: string; // This isn't optional, but we don't want it
  groups?: {
    [key: string]: string;
  };
  metas?: {
    [key: string]: {
      [key: string]: string;
    };
  };
}

export const deviceIdCookie = "device_id";
export const testGroupsCookieHash = "ab_hash";

export const getDeviceId = (request?: Request): string | undefined => {
  if (request) {
    return request.cookies[deviceIdCookie];
  } else if (typeof document !== "undefined") {
    return cookies().get(deviceIdCookie);
  }
};

export const generateDeviceId = () => uuidv4();

const cookieMaxAge = 30 * 6;
export const storeDeviceId = (deviceId: string) => {
  if (typeof document !== "undefined") {
    cookies().set(deviceIdCookie, deviceId, cookieMaxAge);
  }
};

export const storeTestGroups = (testGroups?: ABTestConfig, response?: Response) => {
  if (testGroups) {
    const hashedGroups = md5(stringify(testGroups));
    if (response) {
      response.cookie(testGroupsCookieHash, hashedGroups);
      response.locals.testGroups = testGroups;
    } else if (typeof document !== "undefined") {
      cookies().set(testGroupsCookieHash, hashedGroups);
    }
  }
};

export const getUserTestGroups = async (
  deviceId: string,
  slug: string,
  profile: IHeartUserProfile | null,
  depAbTestHost: string,
  depRequestTimeout: number,
  from: string,
): Promise<ABTestConfig | undefined> => {
  log.debug(`${from} Device ID: ${deviceId}`);
  const baseUrl = depAbTestHost;

  if (!baseUrl) {
    log.debug(`{from} Missing AB Test Host Env Param`);
    return;
  }

  if (typeof window !== "undefined") {
    const qp = new URL(window.location.href).searchParams;
    const deviceIdOverride = qp.get("device_id");
    deviceId = deviceIdOverride || deviceId;
  }

  try {
    let payload: ABTestRequestPayload = {
      meta: {
        deviceId,
        platform: "local",
        local_site_name: slug.toLowerCase(),
      },
      eligibilityControlFilter: "All",
    };

    if (profile?.profileId) {
      payload = {
        ...payload,
        userId: profile.profileId.toString(),
      };
    }

    const config: AxiosRequestConfig = {
      timeout: depRequestTimeout,
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    log.debug(`${from} Request: `, { payload, config });

    return await axios.post<ABTestConfig>(`${baseUrl}/users/groups/query`, payload, config).then(response => {
      log.debug(`${from} AB Config: `, response.data);
      delete response.data.at;
      return response.data;
    });
  } catch (e) {
    log.debug(e.message);
    return Promise.reject({
      at: "",
    });
  }
};

export async function getAndStoreTestGroupInformation(store: Store, depAbTestHost: string, depRequestTimeout: number) {
  const deviceId = getDeviceId() || generateDeviceId();
  const testGroups = await getUserTestGroups(
    deviceId,
    store.site.index.slug,
    null,
    depAbTestHost,
    depRequestTimeout,
    "ihm.ts (refresh)",
  );
  store.testGroups = testGroups;
  storeTestGroups(testGroups);
  storeDeviceId(deviceId);

  return {
    testGroups,
    deviceId,
  };
}
