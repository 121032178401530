import { SimpleEventDispatcher } from "ste-simple-events";
import { getAndStoreTestGroupInformation } from "../../services/variant-test";
import { ILog } from "@ihr-radioedit/inferno-core";
import { localStorage } from "../../services/local-storage";
import type { Store } from "../../stores";
import { SessionBackend, IHRSessionState } from "../../decoders/ihr-user";
import { getProfile, logout } from "../../services/ihr-client";

const log = ILog.logger("IHM Session/ihr-client.ts");

export class IHRSessionBackend implements SessionBackend {
  readonly name = "ihr-auth";
  state: IHRSessionState = { authenticated: false, pid: "", aid: "" };
  private baseUri: string;

  private oauthHost: string;
  private oauthAuthorizePath: string;
  private oauthClientId: string;
  private oauthInfernoRedirectPath: string;
  private oauthInfernoLogoutPath: string;
  private oauthInfernoProfilePath: string;
  private oauthSignupPath: string;
  private _onStatusChanged = new SimpleEventDispatcher<IHRSessionState>();

  constructor(private store: Store, private depAbTestHost: string, private depRequestTimeout: number) {
    if (
      !this.store.env.WEB_ACCOUNT_HOST ||
      !this.store.env.OAUTH_AUTHORIZE_PATH ||
      !this.store.env.OAUTH_SIGNUP_PATH ||
      !this.store.env.OAUTH_CLIENT_ID ||
      !this.store.env.OAUTH_INFERNO_REDIRECT_PATH ||
      !this.store.env.OAUTH_INFERNO_LOGOUT_PATH ||
      !this.store.env.OAUTH_INFERNO_PROFILE_PATH ||
      !this.store.env.NODE_ENV
    ) {
      throw new Error("Required Environment vars not found for IHR Session");
    }

    this.oauthHost = this.store.env.WEB_ACCOUNT_HOST;
    this.oauthAuthorizePath = this.store.env.OAUTH_AUTHORIZE_PATH;
    this.oauthSignupPath = this.store.env.OAUTH_SIGNUP_PATH;
    this.oauthClientId = this.store.env.OAUTH_CLIENT_ID;

    this.baseUri = this.store.api_base_uri;
    this.oauthInfernoRedirectPath = this.store.env.OAUTH_INFERNO_REDIRECT_PATH;
    this.oauthInfernoLogoutPath = this.store.env.OAUTH_INFERNO_LOGOUT_PATH;
    this.oauthInfernoProfilePath = this.store.env.OAUTH_INFERNO_PROFILE_PATH;

    const stored = this.local();
    if (stored) {
      this.state = stored;
      this._onStatusChanged.dispatch(this.state);
    }
  }

  get onStatusChanged() {
    return this._onStatusChanged.asEvent();
  }

  get authenticated() {
    return this.state.authenticated;
  }

  get hasPrivacy() {
    return !!this.state?.profile?.privacy;
  }

  async login() {
    try {
      const authorizationUrl = this.getTargetUrlWithRedirects(
        new URL(this.oauthAuthorizePath, this.oauthHost),
        this.store.request.prNumber,
      );

      const loginToken = this.store.request.query?.loginToken ?? undefined;
      if (loginToken) {
        authorizationUrl.searchParams.set("loginToken", loginToken as string);
      }

      window.location.href = authorizationUrl.toString();
    } catch (_e) {
      log.error("Failed to login", _e);
      throw this.state;
    }
  }

  async signup() {
    try {
      const signUpUrl = this.getTargetUrlWithRedirects(
        new URL(this.oauthSignupPath, this.oauthHost),
        this.store.request.prNumber,
      );
      window.location.href = signUpUrl.toString();
    } catch (_e) {
      log.error("Failed to signup", _e);
      throw this.state;
    }
  }

  async logout() {
    try {
      const oauthLogoutUrl = new URL(this.baseUri + this.oauthInfernoLogoutPath, this.getBase(this.getRedirect()));

      const result = await logout(oauthLogoutUrl.toString());
      if (result) {
        return this.sessionUpdated({ authenticated: false, aid: "", pid: "" });
      }

      throw this.state;
    } catch (_e) {
      log.error("Failed to logout", _e);
      throw this.state;
    }
  }

  async refresh() {
    try {
      const profileUrl = new URL(this.baseUri + this.oauthInfernoProfilePath, this.getBase(this.getRedirect()));

      const result = await getProfile(profileUrl.toString());
      if (result && result?.profile) {
        const { sessionId, profileId } = result.profile;
        return this.sessionUpdated({
          ...this.state,
          profile: result.profile,
          aid: sessionId,
          pid: String(profileId),
          authenticated: true,
        });
      }

      return this.sessionUpdated({ authenticated: false, aid: "", pid: "" });
    } catch (_e) {
      log.error("Failed to refresh", _e);
      throw this.state;
    }
  }

  private getRedirect(type: "url" | "uri" = "url") {
    const prHosts = type === "url" ? this.store.request.prHostname : this.store.request.prHostnameUri;
    const localStagingProdHosts = type === "url" ? this.store.request.hostname : this.store.request.hostnameUri;

    return prHosts || localStagingProdHosts!;
  }

  private getBase(hostName: string) {
    let protocol = this.store.request.protocol;
    if (!protocol.endsWith(":")) {
      protocol += ":";
    }

    return `${protocol}//${hostName}`;
  }

  private getTargetUrlWithRedirects(url: URL, prNumber?: number | false) {
    const redirectUri = new URL(this.baseUri + this.oauthInfernoRedirectPath, this.getBase(this.getRedirect("uri")));

    const redirectUrl = new URL(this.store.request.path, this.getBase(this.getRedirect()));
    const currentSearchParams = new URLSearchParams(window.location.search);
    currentSearchParams.forEach((value, key) => {
      if (key.toLowerCase() === "logintoken") {
        return;
      }
      redirectUrl.searchParams.append(key, value);
    });

    url.searchParams.set("client_id", prNumber ? prNumber.toString() : this.oauthClientId);
    url.searchParams.set("redirect_uri", redirectUri.toString());
    url.searchParams.set("redirectUrl", redirectUrl.toString());

    return url;
  }

  private static stateDataValid(state: IHRSessionState) {
    if (state.authenticated && state.pid && state.aid) {
      return true;
    }
    log.warn("State data is invalid: ", state);
    return false;
  }

  private local() {
    const localData = localStorage.getItem(this.name);
    if (localData) {
      try {
        const state = JSON.parse(localData) as IHRSessionState;
        if (IHRSessionBackend.stateDataValid(state)) {
          log.debug("Found local session data");
          return state;
        } else {
          log.warn("Invalid session data stored, removing it from localStorage");
          localStorage.removeItem(this.name);
        }
      } catch (e) {
        log.debug("Invalid payload in local storage");
      }
    }
    return null;
  }

  private persist(update: IHRSessionState) {
    log.debug("Persisting session data", update);

    this.state = { ...update };

    try {
      localStorage.setItem(this.name, JSON.stringify(this.state));
    } catch (err) {
      log.error(err.message);
      this.persist({ authenticated: update.authenticated, pid: "", aid: "" });
    }
  }

  private async sessionUpdated(state: IHRSessionState) {
    const { authenticated, aid, pid, profile } = state;

    await getAndStoreTestGroupInformation(this.store, this.depAbTestHost, this.depRequestTimeout);

    let persistData: IHRSessionState = {
      authenticated,
      pid: "",
      aid: "",
      profile: undefined,
    };

    if (authenticated) {
      if (profile?.isAnonymous) {
        log.warn("Invalid Account Type: Anonymous");
      } else {
        persistData = { authenticated, pid, aid, profile };
      }
    }

    this.persist(persistData);

    this._onStatusChanged.dispatch(this.state);
    return this.state;
  }
}
