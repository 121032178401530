import { IHRSessionState } from "../../decoders/ihr-user";
import type { Store } from "../../stores";
import { IHRSessionBackend } from "./ihr-client";
import { PWSSessionBackend, PWSSessionState } from "./pws";

export type BackendSessionState = IHRSessionState | PWSSessionState;

export enum SessionType {
  PWS_AUTH = "pws-auth",
  SDK_BRIDGE = "sdk-bridge",
}

export const getSessionBackend = (store: Store, depAbTestHost: string, depRequestTimeout: number) => {
  switch (store.site.config.sections?.partners?.auth_type?.taxo?.name) {
    case "pws-auth":
      return new PWSSessionBackend(store);
    case "sdk-bridge":
    default:
      return new IHRSessionBackend(store, depAbTestHost, depRequestTimeout);
  }
};
