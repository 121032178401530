import { format } from "date-fns";
import i18next from "i18next";
import { inject, observer } from "mobx-react";
import { Component, Fragment, SyntheticEvent } from "react";
import { Translation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { UserIcon } from "../../components/icons/UserIcon.component";

import type { BackendSessionState, Store } from "@inferno/renderer-shared-core";

import { ILog } from "@ihr-radioedit/inferno-core";
import type {
  PostupListSubscription,
  PostupListSubscriptionCreateInput,
  PostupRecipient,
  PostupStation,
  PostupStationList,
} from "@ihr-radioedit/inferno-webapi";
import { PostupChannel, PostupListSubscriptionStatus, PostupRecipientStatus } from "@ihr-radioedit/inferno-webapi";
import { IHeartUserProfile } from "@inferno/renderer-shared-core";
import {
  getRecipient,
  getRecipientSubscriptions,
  getStation,
  POSTUP_RECIPIENT_SOURCE,
  sendRecipient,
  sendSubscriptionLists,
} from "../../services/PostUp";
import { Button, ButtonKind, Container, MagicLink } from "../../ui";
import type { PageBlockInterface } from "../Block.component";

import "./Newsletter.style.scss";

const log = ILog.logger("Subscribe");

interface SubscribeProps extends PageBlockInterface {
  store?: Store;
}

interface SubscribeState {
  loggedIn: boolean;
  stationLists?: PostupStation | null;
  subscriber?: PostupRecipient[] | null;
  subscriberLists?: PostupListSubscription[] | null;
  profile?: IHeartUserProfile | null;
}

@inject("store")
@observer
export class Subscribe extends Component<SubscribeProps, SubscribeState> {
  private saveInProgress = false;
  mounted = false;
  toastId = "subscriptions-update-notify";

  constructor(props: SubscribeProps) {
    super(props);

    this.state = {
      loggedIn: false,
      subscriberLists: [],
    };
  }

  private async update(state: BackendSessionState) {
    if (!this.props.store) {
      return;
    }

    const { site, api_base_uri } = this.props.store;
    const base_uri = api_base_uri || "/api/v4";

    if (site && api_base_uri && state) {
      let subscriber: PostupRecipient[] | null | undefined = null;
      let subscriberLists: PostupListSubscription[] = [];

      if (state.authenticated && state.profile) {
        const recipientVariables = {
          slug: site.index.slug,
          emailAddress: state.profile.email,
        };

        subscriber = await getRecipient({ base_uri, variables: recipientVariables });

        if (subscriber) {
          const recipientSubVariables = {
            slug: site.index.slug,
            emailAddress: subscriber[0].address,
          };

          subscriberLists = (await getRecipientSubscriptions({
            base_uri,
            variables: recipientSubVariables,
          })) as PostupListSubscription[];
        }

        if (this.mounted) {
          this.setState({
            loggedIn: state.authenticated,
            profile: state.profile || null,
            subscriber,
            subscriberLists,
          });
        }
      }
    }
  }

  dismiss = () => toast.dismiss(this.toastId);

  login = () => {
    this.props.store?.onAuthAction.dispatch({
      action: "pending",
    });

    // TODO: This is to prevent race conditions w/ dispatch (subscription) events; should refactor and follow a pattern like forkjoin (rxjs)
    setTimeout(() => {
      this.props.store?.session?.currentSession?.login();
    }, 500);
  };

  logout = () => {
    this.props.store?.session?.currentSession?.logout();
  };

  signup = () => {
    this.props.store?.onAuthAction.dispatch({
      action: "pending",
    });

    // TODO: This is to prevent race conditions w/ dispatch (subscription) events; should refactor and follow a pattern like forkjoin (rxjs)
    setTimeout(() => {
      this.props.store?.session?.currentSession?.signup();
    }, 500);
  };

  save = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (!this.props.store || this.saveInProgress) {
      return;
    }

    this.saveInProgress = true;

    const { profile } = this.state;
    if (!profile || !profile.email || !this.state.subscriberLists) {
      return null;
    }

    const userName = (profile.name || profile.email || "").split("@")[0];
    let birthDate = "";
    if (profile.birthYear && profile.birthMonth && profile.birthDay) {
      birthDate = profile.birthMonth + "/" + profile.birthDay + "/" + profile.birthYear;
    }

    const { site, api_base_uri } = this.props.store;
    const base_uri = api_base_uri || "/api/v4";
    const subscriber = this.state.subscriber && this.state.subscriber[0];

    const recipient: any = {
      address: profile.email,
      externalId: profile.email,
      channel: PostupChannel.E,
      status: subscriber?.status && (PostupRecipientStatus as any)[subscriber?.status],
      comment: subscriber?.comment,
      sourceDescription: POSTUP_RECIPIENT_SOURCE,
      demographics: [
        `FirstName=${userName}`,
        `_PostalCode=${profile.zipCode || ""}`,
        `_Gender=${profile.gender || ""}`,
        `_DateOfBirth=${birthDate}`,
      ],
    };

    if (subscriber && subscriber.recipientId) {
      recipient.recipientId = subscriber.recipientId;
      delete recipient.sourceDescription;
    }

    try {
      toast.info(i18next.t("subscriptions_success"), {
        autoClose: 10000,
        toastId: this.toastId,
      });

      const variables = {
        slug: site.index.slug,
        input: recipient,
      };

      const recipientResponse = await sendRecipient({
        variables,
        base_uri,
      });

      if (recipientResponse) {
        // add recipientId to subscriberLists for new subscriber
        const subscriberData = recipientResponse as PostupRecipient;
        const subscriberLists = this.state.subscriberLists.map(list => {
          if (list) {
            list.recipientId = subscriberData.recipientId;
          }
          return list;
        });

        if (this.mounted) {
          this.setState({
            subscriber: [subscriberData],
            subscriberLists,
          });
        }

        const subscriptionInput: PostupListSubscriptionCreateInput[] = this.state.subscriberLists.map(suber => ({
          listId: suber.listId,
          recipientId: suber.recipientId,
          status: suber.status as PostupListSubscriptionStatus,
          sourceId: suber.sourceId,
        }));

        const subscriptionListResponse = await sendSubscriptionLists({
          variables: { input: subscriptionInput },
          base_uri,
        });

        if (subscriptionListResponse) {
          log.info("Subscriber data updated");
          this.saveInProgress = false;
        }
      } else {
        // dismiss the update toast
        this.dismiss();

        // null response means there's some error
        toast.info(i18next.t("subscriptions_error"), {
          autoClose: 3000,
          toastId: "subscriptions-error-notify",
        });
        log.error(`An error occurred while updating Recipient: ${recipientResponse} `);
        this.saveInProgress = false;
      }
    } catch (err) {
      log.error("An error occurred while updating subscriber data: ", err);
    }
  };

  async componentDidMount() {
    this.mounted = true;
    if (!this.props.store) {
      return;
    }

    this.props.store.storeBlock(this.props.block);

    const { site, session, api_base_uri } = this.props.store;
    const base_uri = api_base_uri || "/api/v4";

    if (site && session && api_base_uri) {
      if (session.currentSession) {
        session.currentSession.onStatusChanged.subscribe(state => this.update(state));
      }

      const stationData = await getStation(site.index.slug, base_uri);

      if (this.mounted) {
        this.setState(
          {
            stationLists: stationData,
          },
          () => {
            if (session.currentSession) {
              this.update(session.currentSession.state);
            }
          },
        );
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  static checkListStatus = (listId: number, lists: PostupListSubscription[]) => {
    if (!listId || !lists) {
      return false;
    }

    let checked = false;
    lists.map(list => {
      if (listId === list.listId && list.status === "NORMAL") {
        checked = true;
      }
    });

    return checked;
  };

  private changeListStatus = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;

    if (target) {
      let subscriberLists: any = [];
      if (this.state.subscriberLists && this.state.subscriberLists.length) {
        // for existing subscriber list
        subscriberLists = this.state.subscriberLists.map(list => {
          if (list) {
            if (target.value === list.listId.toString()) {
              list.status = !target.checked ? PostupListSubscriptionStatus.Unsub : PostupListSubscriptionStatus.Normal;
            }
          }
          return list;
        });
      } else if (this.state.stationLists?.lists && this.state.stationLists.lists.length) {
        // first time subscriptions
        const { subscriber } = this.state;
        subscriberLists = this.state.stationLists.lists.map(list => {
          let status = "UNSUB";
          if (target.value === list.listId.toString()) {
            status = !target.checked ? PostupListSubscriptionStatus.Unsub : PostupListSubscriptionStatus.Normal;
          }
          const formatted = format(new Date(), "yyyy-MM-dd HH:mm:ss", {
            useAdditionalWeekYearTokens: true,
          });
          // convert stationList to subscribeList
          return {
            listId: list.listId,
            recipientId: subscriber ? subscriber[0].recipientId : "",
            status,
            dateJoined: formatted,
            address: subscriber ? subscriber[0].address : "",
            sourceId: "newsletter",
            confirmed: true,
          };
        });
      }
      const latestSubscriberLists = subscriberLists;

      if (this.mounted) {
        this.setState({ subscriberLists: latestSubscriberLists });
      }
    }
  };

  render() {
    if (!this.props.store) {
      return null;
    }

    const { site } = this.props.store;
    const { profile } = this.state;

    if (!this.state.loggedIn || !profile) {
      return (
        <Container className="component-newsletter">
          <Translation>
            {t => (
              <div className="description">
                {t("subscriptions_login_desc", {
                  station: `${site.sections.general?.name}`,
                })}
                <button onClick={this.signup}>{t("subscriptions_signup")}</button>
                {t("subscriptions_login_cta")}
              </div>
            )}
          </Translation>
          <Translation>
            {t => (
              <div className="button-wrapper">
                <Button className={"login-btn"} kind={ButtonKind.PRIMARY} click={this.login}>
                  {t("login")}
                </Button>
                <Button className={"signup-btn"} kind={ButtonKind.SECONDARY} click={this.signup}>
                  {t("subscriptions_signup")}
                </Button>
              </div>
            )}
          </Translation>
        </Container>
      );
    }

    const userName = (profile.name || profile.email || "").split("@")[0];

    let birthDate = "";
    if (profile.birthYear && profile.birthMonth && profile.birthDay) {
      birthDate = profile.birthMonth + " / " + profile.birthDay + " / " + profile.birthYear;
    } // if

    const profilePic = profile.profileId ? (
      <MagicLink to={`${this.props.store?.env.WEB_ACCOUNT_HOST}`} target="_blank">
        <img
          className="profile-pic lazyload"
          alt="Profile Image"
          data-src={`//i.iheart.com/v3/user/${profile.profileId}/profile?ops=fit(88%2C88)`}
        />
      </MagicLink>
    ) : (
      <UserIcon className="profile-pic" />
    );

    return (
      <Fragment>
        <Container className="component-newsletter">
          <Translation>
            {t => (
              <div className="login-as">
                {t("subscriptions_loggedin_as")}
                {userName}
                {t("subscriptions_notyou")}
                <button onClick={this.logout}>{t("subscriptions_logout")}</button>
              </div>
            )}
          </Translation>
          <Translation>
            {t => (
              <div className="description">
                {t("subscriptions_update_desc").trimRight()}&nbsp;
                <MagicLink to={`${this.props.store?.env.WEB_ACCOUNT_HOST}`} target="_blank">
                  {t("subscriptions_update_settings")}
                </MagicLink>
              </div>
            )}
          </Translation>
          <section className="user">
            <form role="form" noValidate={true}>
              <div className="profile">
                <div className="user-image-wrapper">{profilePic}</div>
                <div className="user-profile-details">
                  <div className="profile-row">
                    <span className="label">{i18next.t("name")}</span>
                    <span className="data">{userName}</span>
                  </div>
                  <div className="profile-row">
                    <span className="label">{i18next.t("email")}</span>
                    <span className="data">{profile.email}</span>
                  </div>
                  <div className="profile-row password">
                    <span className="label">{i18next.t("password")}</span>
                    <span className="data">&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</span>
                  </div>
                  {profile.gender ? (
                    <div className="profile-row">
                      <span className="label">{i18next.t("gender")}</span>
                      <span className="data">{profile.gender}</span>
                    </div>
                  ) : null}
                  {profile.phoneNumber ? (
                    <div className="profile-row">
                      <span className="label">{i18next.t("phone")}</span>
                      <span className="data">{profile.phoneNumber}</span>
                    </div>
                  ) : null}
                  {profile.zipCode ? (
                    <div className="profile-row">
                      <span className="label">{i18next.t("zip")}</span>
                      <span className="data">{profile.zipCode}</span>
                    </div>
                  ) : null}
                  {birthDate ? (
                    <div className="profile-row">
                      <span className="label">{i18next.t("birthdate")}</span>
                      <span className="data">{birthDate}</span>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="options">
                <Translation>{t => <div className="options-header">{t("subscriptions_options")}</div>}</Translation>
                <ul className="station-lists">
                  {this.state.stationLists?.lists?.map((list: PostupStationList, index: number) => {
                    if (list.publicSignup) {
                      return (
                        <li key={index}>
                          <input
                            type="checkbox"
                            name={`stationsLists[${index}]`}
                            id={`station-list-${list.listId}`}
                            value={list.listId}
                            defaultChecked={Subscribe.checkListStatus(list.listId, this.state.subscriberLists || [])}
                            onChange={this.changeListStatus}
                            aria-labelledby={`station-list-label-${list.listId}`}
                          />
                          <label htmlFor={`station-list-${list.listId}`} id={`station-list-label-${list.listId}`}>
                            {list.friendlyTitle || list.title}
                          </label>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
              <Translation>
                {t => (
                  <div className="button-wrapper">
                    <Button className={"save-btn"} kind={ButtonKind.PRIMARY} click={this.save}>
                      {t("subscriptions_save")}
                    </Button>
                  </div>
                )}
              </Translation>
            </form>
          </section>
        </Container>
        <ToastContainer autoClose={5000} position="bottom-center" />
      </Fragment>
    );
  }
}

export default Subscribe;
