import axios from "axios";
import { type IHRServerSessionState } from "../decoders/ihr-user";

export async function logout(url: string) {
  const response = await axios.post(url);

  if (response.status !== 200) {
    throw new Error("Failed to logout");
  }

  return true;
}

export async function getProfile(url: string): Promise<IHRServerSessionState | null> {
  const response = await axios.get(url);

  if (response.status === 200) {
    return response.data;
  }

  // INFO: User never had a cookie
  if (response.status === 204) {
    return null;
  }

  throw new Error(response?.data?.error ?? "Failed to get profile");
}
